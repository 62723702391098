<!--
 * @Description: 数据字典选项列表
 * @Author: 小广
 * @Date: 2020-05-06 09:28:38
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-06 16:12:50
 -->
<template>
  <div class="DataDictionaryOptionList">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :extraParams="extraParams"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
        <v-button text="返回" @click="goBack" />
      </template>
      <template #searchSlot>
        <v-input v-model="searchParams.value" label="字典选项" :width="width" />
        <v-input v-model="searchParams.code" label="选项key" :width="width"/>
        <!-- <v-select v-model="searchParams.status" label="状态" :options="statusOps" :width="width"/> -->
        <v-input v-model="searchParams.updateUser" label="操作人" :width="width"/>
        <!-- <v-select2 label="操作人" v-model="searchParams.updateUserId" v-bind="operatorParams" :subjoin="{type:'106'}" :width="width"></v-select2> -->
        <v-datepicker :maxDate="maxDate" :startTime.sync="searchParams.updateTimeFrom" :endTime.sync="searchParams.updateTimeEnd" type="rangedatetimer" label="操作时间" />
      </template>
      <template #operateSlot="scope">
        <v-button type="text" text="编辑" permission="update" @click="update(scope.row)" />
        <v-button type="text" v-if="scope.row && scope.row.hasAddChildButton === 'y'" text="添加子类" permission="addSub" @click="addSubAction(scope.row)" />
        <!-- <v-button type="text" text="删除" permission="delete" @click="deleteAction(scope.row)" /> -->
      </template>
    </list>
  </div>
</template>

<script>
import { queryOptionListURL, deleteURL, getOperatorSelect2URL } from './api'
import { setStatusOps } from './map'
import moment from 'moment'

export default {
  name: 'DataDictionaryOptionList',
  data () {
    return {
      width: 160,
      maxDate: moment().format('YYYY-MM-DD HH:mm'),
      searchUrl: queryOptionListURL,
      searchParams: {
        value: '',
        code: '',
        status: undefined,
        updateUser: '',
        updateTimeFrom: '',
        updateTimeEnd: '',
        updateUserId: ''
      },
      // 操作人的select2参数（暂未使用）
      operatorParams: {
        searchUrl: getOperatorSelect2URL,
        // method: 'POST'
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      headers: [
        {
          prop: 'value',
          label: '字典选项'
        },
        {
          prop: 'code',
          label: '选项key'
        },
        {
          prop: 'parentCodeWordValue',
          label: '父级'
        },
        {
          prop: 'sort',
          label: '排序'
        },
        {
          prop: 'codeLevel',
          label: '层级'
        },
        // {
        //   prop: 'statusStr',
        //   label: '状态'
        // },
        {
          prop: 'updateUserName',
          label: '操作人'
        },
        {
          prop: 'updateTimeStr',
          label: '操作时间'
        }
      ],
      statusOps: setStatusOps(1)
    }
  },

  computed: {
    extraParams () {
      return { codeWordTypeId: this.$route.query.codeWordTypeId }
    }
  },

  created () {
    let typeName = this.$route.query.typeName
    if (typeName && typeName.length) {
      this.$setBreadcrumb(typeName)
    }
  },
  methods: {
    create () {
      let typeId = this.$route.query.codeWordTypeId
      this.$router.push({
        name: 'dataDictionaryOptionForm',
        query: {
          typeId: typeId
        }
      })
    },
    update (row) {
      this.$router.push({
        name: 'dataDictionaryOptionForm',
        query: {
          id: row.id,
          editType: '1'
        }
      })
    },
    async deleteAction (row) {
      let isOK = await this.$confirm('确认删除该数据字典选项？', { title: '提示' })
      isOK && this.requestForDelete(row)
    },
    addSubAction (row) {
      let typeId = this.$route.query.codeWordTypeId
      this.$router.push({
        name: 'dataDictionaryOptionForm',
        query: {
          typeId: typeId,
          parentId: row.id,
          parentName: row.value,
          editType: '2'
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    },

    // 请求
    async requestForDelete (row) {
      let params = {
        id: row.id
      }
      const { status } = await this.$axios.get(deleteURL, { params })
      if (status === 100) {
        this.$refs.list.searchData()
        this.$message.success('操作成功')
      }
    }
  }
}
</script>
