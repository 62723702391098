import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 'enabled'
  },
  {
    text: '关闭',
    value: 'disabled'
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 编辑类型
const editType = [
  {
    text: '新增',
    value: 0
  },
  {
    text: '编辑',
    value: 1
  },
  {
    text: '新增子类',
    value: 2
  }
]

const {
  map: editTypeMap,
  setOps: editTypeOps
} = mapHelper.setMap(editType)

export {
  statusMap,
  setStatusOps,
  editTypeMap,
  editTypeOps
}
