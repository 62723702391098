
// const controlBaseURL = 'http://192.168.10.216:7020/manage/'
// 查询列表
const queryListURL = `${API_CONFIG.controlBaseURL}sysCodewordType/list`
// 查询Option列表
const queryOptionListURL = `${API_CONFIG.controlBaseURL}sysCodeword/management/list`
// 查询详情
const queryDetailURL = `${API_CONFIG.controlBaseURL}sysCodeword/detail`
// 新增
const createURL = `${API_CONFIG.controlBaseURL}sysCodeword/add`
// 新增子主库字典
const createChildURL = `${API_CONFIG.controlBaseURL}sysCodeword/addChild`
// 编辑
const updateURL = `${API_CONFIG.controlBaseURL}sysCodeword/edit`
// 删除 （暂未使用）
const deleteURL = `${API_CONFIG.controlBaseURL}deleteURL`

// 获取操作人的select2 （暂未使用）
const getOperatorSelect2URL = `${API_CONFIG.controlBaseURL}getOperatorSelect2URL`

export {
  queryListURL,
  queryOptionListURL,
  queryDetailURL,
  createURL,
  updateURL,
  createChildURL,
  deleteURL,
  getOperatorSelect2URL
}
